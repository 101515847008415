import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { connect } from "react-redux"
import useCurrentUser from "hooks/useCurrentUser"
import useMixpanelTracking from "hooks/useMixpanelTracking"
import useFlag from "hooks/useFlag"
import { Link } from "react-router"
import withAccountLinks from "hocs/withAccountLinks"
import ThemeModeToggle from "components/ThemeModeToggle"
import { openLiveChat } from "utils/crisp"
import { actions as uiActions } from "ducks/ui"
import { useDispatch } from "react-redux"
import { actions as modalActions } from "ducks/modal"
import useAppNotifications from "hooks/useAppNotifications"
import { playlistRequestCrispChatOpen } from "utils/crisp"
import { loggedIn } from "utils/authentication"
import Box from "components/Box"
import { Text } from "components/Typography"
import Icon from "components/Icon"
import {
  ICON_SS_CHEVRON_DOWN,
  ICON_SS_CHEVRON_RIGHT,
  ICON_UI_PLAYLISTS,
  ICON_SS_HEART_EMPTY,
  ICON_UI_PROJECTS_OUTLINE,
  ICON_SS_FOLLOW,
  ICON_SS_DOWNLOAD,
} from "components/Icon/constants"

const Wrapper = styled.div`
  animation: fadeIn 0.05s ease-out 0.05s forwards;
  background: ${(props) => props.theme.colors.background.layered};
  position: absolute;
  top: 100%;
  right: 12px;
  z-index: ${(props) => props.theme.zIndices.dropdown};
  border-radius: ${(props) => props.theme.radii.default};
  box-shadow: ${(props) => props.theme.shadows.dropdown};
  width: 208px;
  overflow: hidden;
  user-select: none;
`

const SubWrapper = styled.div`
  padding: ${(props) => props.theme.space[3]} 0;
  width: 100%;
`

const optionStyles = css`
  color: ${(props) => props.theme.colors.text.primary};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.sm};
  padding: ${(props) => props.theme.space[2]} ${(props) => props.theme.space[5]};
  line-height: 20px;
  text-decoration: none;
  transition: all ${(props) => props.theme.transitions.quick};
  letter-spacing: ${(props) => props.theme.letterSpacings[1]};
  vertical-align: middle;
  font-weight: ${(props) => props.theme.fontWeights.medium};
`

const FooterWrapper = styled(SubWrapper)`
  ${optionStyles};
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${(props) => props.theme.space[5]};

  &:hover {
    color: ${(props) => props.theme.colors.action.hover};
    background-color: ${(props) => props.theme.colors.background.tertiary};
  }
`

const Option = styled(Link)`
  ${optionStyles};
  display: inline-flex;
  width: 100%;
  &:hover {
    background-color: ${(props) => props.theme.colors.background.accentSubtle};
  }

  svg {
    color: currentcolor;
  }
`

const SubOption = styled(Link)`
  color: ${(props) => props.theme.colors.text.secondary};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.xs};
  padding: ${(props) => props.theme.space[2]} ${(props) => props.theme.space[6]};
  line-height: 20px;
  text-decoration: none;
  letter-spacing: ${(props) => props.theme.letterSpacings[1]};
  vertical-align: middle;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  display: block;

  &:hover {
    background-color: ${(props) => props.theme.colors.background.accentSubtle};
  }
`

const OptionHeader = styled.button`
  align-items: center;
  line-height: 20px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.text.primary};
  display: inline-flex;
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  transition: all ${(props) => props.theme.transitions.quick};
  letter-spacing: ${(props) => props.theme.letterSpacings[1]};
  padding: ${(props) => props.theme.space[2]} ${(props) => props.theme.space[5]};
  justify-content: space-between;

  width: 100%;

  svg {
    color: currentcolor;
    margin-left: ${(props) => props.theme.space[1]};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.background.accentSubtle};
  }
`
const MyMediaWrapper = styled(Box)`
  background-color: ${(props) => props.theme.colors.background.inset};
  width: 100%;
`

const ThemeWrapper = styled.div`
  ${optionStyles};
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.background.accentSubtle};
  }
`

const ThemeLabel = styled.label`
  width: 100%;
  cursor: pointer;
`

const Divider = styled.div`
  background: ${(props) => props.theme.colors.divider};
  height: 1px;
  width: 100%;
`

const Dropdown = ({
  toggleThemeMode,
  notificationsLinkObject,
  billingLinkObject,
  contentIdLinkObject,
  mobileAppLinkObject,
  extensionsLinkObject,
  frameioLinkObject,
  profileLinkObject,
  referralLinkObject,
  signOutLinkObject,
  teamMembersLinkObject,
  subscriptionLinkObject,
  apiAccessLinkObject,
}) => {
  const { currentUser } = useCurrentUser()
  const dispatch = useDispatch()
  const [showAccountSubmenu, setShowAccountSubmenu] = useState(false)
  const [showHelpSubmenu, setShowHelpSubmenu] = useState(false)
  const { trackMixpanel } = useMixpanelTracking()
  const { enabled: newAvatarMenu } = useFlag("new_avatar_menu")
  const { enabled: appNotificationsEnabled } = useAppNotifications()

  useEffect(() => {
    trackMixpanel("Viewed Account Avatar Menu")
  })

  const toggleAccountMenu = () => {
    setShowAccountSubmenu(!showAccountSubmenu)
  }

  const toggleHelpMenu = () => {
    setShowHelpSubmenu(!showHelpSubmenu)
  }

  const handleOpenFeatureTour = (e) => {
    handleMixpanelTracking("Feature Tour")
    e.preventDefault()
    dispatch(modalActions.open("FeatureTourModal"))
  }

  const handleOpenLiveChat = (e) => {
    handleMixpanelTracking("Live Chat")
    e.preventDefault()
    openLiveChat()
  }

  const handleMixpanelTracking = (element) => {
    trackMixpanel("Clicked Element", {
      context: "Account Avatar",
      element: element,
    })
  }

  const renderSubLink = (link) =>
    link && !link.hidden ? (
      <SubOption
        onClick={() => handleMixpanelTracking(link.content)}
        {...link.props}
      >
        {link.content}
      </SubOption>
    ) : null

  const renderLink = (link) =>
    link && !link.hidden ? (
      <Option
        onClick={() => handleMixpanelTracking(link.content)}
        {...link.props}
      >
        {link.content}
      </Option>
    ) : null

  const renderAction = (text, onClick, visible = true) =>
    visible && <SubOption onClick={onClick}>{text}</SubOption>

  const renderMediaMenu = () => {
    const onTeam = currentUser.onTeam()

    return (
      <Box>
        <Box pb={3} pt={3} ml={5}>
          <Text color="text.secondary" fontSize="sm" fontWeight="semiBold">
            My Media
          </Text>
        </Box>

        <Option
          onClick={() => handleMixpanelTracking("My Media - Favorites")}
          to="/my_media/favorites/music"
        >
          <Box display="flex">
            <Icon size={20} icon={ICON_SS_HEART_EMPTY}></Icon>
            <Box pl={3}>Favorites</Box>
          </Box>
        </Option>

        <Option
          onClick={() => handleMixpanelTracking("My Media - Following")}
          to="/my_media/following/playlists"
        >
          <Box display="flex">
            <Icon size={20} icon={ICON_SS_FOLLOW}></Icon>
            <Box pl={3}>Following</Box>
          </Box>
        </Option>

        {onTeam && (
          <Box pb={3} pt={3} ml={5}>
            <Text color="text.secondary" fontSize="sm" fontWeight="semiBold">
              Team Media
            </Text>
          </Box>
        )}

        <Option
          onClick={() => handleMixpanelTracking("My Media - Playlists")}
          to="/my_media/playlists/music"
        >
          <Box display="flex">
            <Icon size={20} icon={ICON_UI_PLAYLISTS}></Icon>
            <Box pl={3}>Playlists</Box>
          </Box>
        </Option>

        {!currentUser.enterpriseApi() && (
          <Option
            onClick={() => handleMixpanelTracking("My Media - Projects")}
            to="/my_media/projects"
          >
            <Box display="flex">
              <Icon
                size={20}
                strokeWidth={1.5}
                icon={ICON_UI_PROJECTS_OUTLINE}
              ></Icon>
              <Box pl={3}>Projects</Box>
            </Box>
          </Option>
        )}

        <Option
          onClick={() => handleMixpanelTracking("My Media - Downloads")}
          to="/licenses/music"
        >
          <Box display="flex">
            <Icon size={20} icon={ICON_SS_DOWNLOAD}></Icon>
            <Box pl={3}>Downloads</Box>
          </Box>
        </Option>

        {currentUser.canRequestPlaylist() && (
          <>
            <Option
              onClick={() =>
                handleMixpanelTracking("My Media - Request Playlist")
              }
              to=""
            >
              <Box
                onClick={() => {
                  playlistRequestCrispChatOpen(currentUser.email)
                }}
              >
                Request Playlist
              </Box>
            </Option>
          </>
        )}
      </Box>
    )
  }

  return (
    <Wrapper>
      {newAvatarMenu && loggedIn() && (
        <MyMediaWrapper pb={2}>{renderMediaMenu()}</MyMediaWrapper>
      )}

      <Box>
        <OptionHeader onClick={toggleAccountMenu}>
          Account
          <Icon
            icon={
              showAccountSubmenu ? ICON_SS_CHEVRON_DOWN : ICON_SS_CHEVRON_RIGHT
            }
          ></Icon>
        </OptionHeader>
        {showAccountSubmenu && (
          <Box>
            {renderSubLink(profileLinkObject)}
            {renderSubLink(subscriptionLinkObject)}
            {renderSubLink(billingLinkObject)}
            {renderSubLink(contentIdLinkObject)}
            {renderSubLink(teamMembersLinkObject)}
            {renderSubLink(apiAccessLinkObject)}
          </Box>
        )}
      </Box>
      <Box pb={3}>
        {currentUser?.canAccessReferralProgram() &&
          renderLink(referralLinkObject)}
        {appNotificationsEnabled && renderLink(notificationsLinkObject)}
        {renderLink(mobileAppLinkObject)}
        {renderLink(extensionsLinkObject)}
        {renderLink(frameioLinkObject)}
        <Box>
          <OptionHeader onClick={toggleHelpMenu}>
            Help
            <Icon
              icon={
                showHelpSubmenu ? ICON_SS_CHEVRON_DOWN : ICON_SS_CHEVRON_RIGHT
              }
            ></Icon>
          </OptionHeader>
          {showHelpSubmenu && (
            <Box>
              {renderAction("Live Chat", handleOpenLiveChat)}
              {renderAction("Start Tour", handleOpenFeatureTour)}
            </Box>
          )}
        </Box>
        <ThemeWrapper
          onClick={(e) => {
            e.stopPropagation()
            toggleThemeMode()
          }}
        >
          <ThemeLabel>Dark Mode</ThemeLabel>
          <ThemeModeToggle />
        </ThemeWrapper>
      </Box>
      <Divider />
      <FooterWrapper {...signOutLinkObject.props}>
        {signOutLinkObject.content}
      </FooterWrapper>
    </Wrapper>
  )
}

Dropdown.propTypes = {
  notificationsLinkObject: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  apiAccessLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  billingLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  contentIdLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  mobileAppLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  extensionsLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  frameioLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  profileLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  referralLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  signOutLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  teamMembersLinkObject: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
}

const mapDispatchToProps = (dispatch) => ({
  toggleThemeMode: () => {
    dispatch(uiActions.toggleThemeMode())
  },
})

export default connect(null, mapDispatchToProps)(withAccountLinks(Dropdown))
