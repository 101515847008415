import { call, put, delay, takeLatest, select } from "redux-saga/effects"
import { patch } from "utils/request"
import { types } from "ducks/recentlyPlayed"

import {
  actions as currentUserActions,
  selectCurrentUserRecord,
} from "ducks/currentUser"

import {
  types as audioPlayerTypes,
  selectSongAudioPlayerIsPlaying,
  selectAudioPlayerSong,
} from "ducks/audioPlayer"

const ACTIONS_FILTER = [types.PLAY, types.TOGGLE_PAUSE]

export function* createRecentlyPlayedMedia(action) {
  if (ACTIONS_FILTER.includes(action.type)) {
    return null
  }

  const audioPlayerIsPlaying = yield select(selectSongAudioPlayerIsPlaying())

  if (action.type === audioPlayerTypes.TOGGLE_PAUSE && !audioPlayerIsPlaying) {
    return null
  }

  try {
    yield delay(3000)

    const userRecord = yield select(selectCurrentUserRecord())
    if (!userRecord.id) return

    const requestURL = `${process.env.API_URL}/users/${userRecord.id}/update_recently_played`

    const song = yield select(selectAudioPlayerSong())

    if (song.type !== "songs") return

    if (!window.location.pathname.includes("/recently_played")) {
      const data = {
        song_id: song.id,
      }

      const options = {
        data: JSON.stringify(data),
      }
      yield call(patch, requestURL, options)
      yield put(currentUserActions.incrementRecentlyPlayedSongsCount())
    }
  } catch (error) {
    // this should fail quietly
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error("RECENTLY PLAYED SAGA ERROR:", error)
    }
  }
}

export function* watchCreateRecentlyPlayedMedia() {
  yield takeLatest(
    [
      audioPlayerTypes.PLAY,
      audioPlayerTypes.TOGGLE_PAUSE,
      audioPlayerTypes.STOP,
      audioPlayerTypes.PAUSE,
    ],
    createRecentlyPlayedMedia
  )
}

export default [watchCreateRecentlyPlayedMedia]
