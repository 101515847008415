import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import classNames from "classnames"
import { color } from "styled-system"

const SvgIcon = styled.svg`
  ${color}
  display: inline-block;
  ${(props) =>
    props.shouldStroke
      ? `
    fill: none !important;
    stroke: currentColor;
    stroke-width: ${props.strokeWidth || "1.5"};
  `
      : `
    fill: ${props.fill || "currentColor"};
    stroke: none;
  `}

  @media (hover: hover) {
    &:hover {
      color: ${(props) =>
        props.hoverColor || props.color || props.theme.colors.text.primary};
    }
  }

  &.active {
    &:active {
      opacity: 0.6;
    }
  }
  &.thick {
    stroke-width: 4px !important;
  }
`

const Icon = (props) => {
  const shouldStroke = props.strokeWidth || props.icon[2] === "stroke"
  const className = classNames(
    { pointer: props.onClick },
    { active: props.onClick },
    props.className
  )

  const strokeProps = shouldStroke
    ? {
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }
    : {}

  return (
    <SvgIcon
      className={className}
      data-cy={props["data-cy"]}
      data-cy-info={props["data-cy-info"]}
      color={props.color}
      height={props.size}
      hoverColor={props.hoverColor}
      onClick={props.onClick}
      preserveAspectRatio="xMidYMid meet"
      style={props.style}
      viewBox={props.icon[1]}
      width={props.size}
      shouldStroke={shouldStroke}
      strokeWidth={props.strokeWidth}
      fill={shouldStroke ? "none" : props.fill}
      {...strokeProps}
    >
      <g>
        {Array.isArray(props.icon[0]) ? (
          props.icon[0].map((pathData, index) => {
            // Get path-specific styles if provided
            const pathStyle =
              props.pathStyles && props.pathStyles[index]
                ? props.pathStyles[index]
                : {}
            return (
              <path
                key={index}
                d={pathData}
                fill={pathStyle.fill || "inherit"}
                stroke={pathStyle.stroke || "inherit"}
                strokeWidth={pathStyle.strokeWidth || "inherit"}
                fillRule={pathStyle.fillRule || props.fillRule}
                clipRule={pathStyle.clipRule || props.clipRule}
                strokeLinecap={pathStyle.strokeLinecap}
                strokeLinejoin={pathStyle.strokeLinejoin}
              />
            )
          })
        ) : (
          <path
            d={props.icon[0]}
            fill="inherit"
            stroke="inherit"
            strokeWidth="inherit"
            fillRule={props.fillRule}
            clipRule={props.clipRule}
          />
        )}
      </g>
    </SvgIcon>
  )
}

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  "data-cy": PropTypes.string,
  "data-cy-info": PropTypes.string,
  hoverColor: PropTypes.string,
  icon: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string, // For path strings or viewBox
      PropTypes.arrayOf(PropTypes.string), // For arrays of path strings
      PropTypes.oneOf(["stroke"]), // For the stroke indicator
    ])
  ).isRequired,
  pathStroke: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: PropTypes.object,
  fill: PropTypes.string,
  fillRule: PropTypes.string,
  clipRule: PropTypes.string,
  strokeWidth: PropTypes.string,
  strokeLinecap: PropTypes.string,
  strokeLinejoin: PropTypes.string,
  pathStyles: PropTypes.arrayOf(
    PropTypes.shape({
      fill: PropTypes.string,
      stroke: PropTypes.string,
      strokeWidth: PropTypes.string,
      fillRule: PropTypes.string,
      clipRule: PropTypes.string,
      strokeLinecap: PropTypes.string,
      strokeLinejoin: PropTypes.string,
    })
  ),
}

Icon.defaultProps = {
  pathStroke: "none",
  color: "text.secondary",
  size: 16,
  style: {},
}

export default Icon
