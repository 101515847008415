import duckSagas from "sagas"
import { LOCAL_STORAGE_KEY as REFERRAL_LOCAL_STORAGE_KEY } from "ducks/referral"
import Cookie from "js-cookie"
import {
  MARKETING_FUNNEL_COOKIE,
  ADOBE_REGISTRATION_COOKIE,
} from "middlewares/cookies"
import { loggedIn, storeToken } from "utils/authentication"
import { getAsyncInjectors } from "./utils/asyncInjectors"

const storeJS = require("utils/store.min.js")

const errorLoading = (err) => console.error("Dynamic page loading failed", err)

const loadModule = (cb) => (componentModule) =>
  cb(null, componentModule.default)

// User cannot be logged in to access
const preventAuth = (_nextState, replace, cb) => {
  if (loggedIn()) {
    replace({
      pathname: "/",
      state: {
        redefined: true, // for progress bar
      },
    })
  }
  cb()
}

// Need to redirect to Checkout page if user is logged in and coming from
// Marketing site pricing page
const signUpRedirect = ({ location: { query } }, replace, cb) => {
  if (loggedIn()) {
    replace({
      pathname: query && query.plan ? "/checkout" : "/",
      query,
      state: {
        redefined: true, // for progress bar
      },
    })
  }
  cb()
}

// User must be logged in to access
const requireAuth = (nextState, replace, cb) => {
  if (!loggedIn()) {
    replace({
      pathname: "/signup",
      state: {
        nextPathname: nextState.location.pathname,
        redefined: true, // for progress bar
      },
    })
  }
  cb()
}

// User must be logged in or have token from Marketing funnel to access
const checkoutAuth = (nextState, replace, cb) => {
  const { auth, ...query } = nextState.location.query
  if (!loggedIn() && auth) {
    storeToken(auth)
    replace({
      pathname: "/checkout",
      query,
      state: {
        redefined: true, // for progress bar
      },
    })
  } else if (!loggedIn() && Cookie.get(ADOBE_REGISTRATION_COOKIE)) {
    replace({
      pathname: "/sign_in",
      query: nextState.location.query,
      state: {
        nextPathname: nextState.location.pathname,
        redefined: true, // for progress bar
      },
    })
  } else if (!loggedIn() && !Cookie.get(MARKETING_FUNNEL_COOKIE)) {
    replace({
      pathname: "/signup",
      query: nextState.location.query,
      state: {
        nextPathname: nextState.location.pathname,
        redefined: true, // for progress bar
      },
    })
  }
  cb()
}

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store)
  injectSagas(duckSagas)

  return [
    {
      path: "/",
      getComponent(_nextState, cb) {
        import("pages/BrowsePage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path:
        "/royalty-free-music/:categoryType(genre|mood|characteristic|instrument)",
      getComponent(_nextState, cb) {
        import("pages/SongsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/royalty-free-music/:category/:tagFilter",
      getComponent(_nextState, cb) {
        import("pages/SongsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/royalty-free-music/:genreName",
      onEnter: ({ params, location: { query } }, replace) =>
        replace({
          pathname: `/royalty-free-music/genre/${params.genreName}`,
          query,
        }),
    },
    {
      path: "/royalty-free-music",
      getComponent(_nextState, cb) {
        import("pages/SongsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/music",
      onEnter: ({ location: { query } }, replace) =>
        replace({ pathname: "/royalty-free-music", query }),
    },
    {
      path: "/songs/:id",
      getComponent(_nextState, cb) {
        import("pages/SongPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/sale/:id/download",
      getComponent(_nextState, cb) {
        import("pages/DownloadPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/referral",
      onEnter: requireAuth,
      getComponent(_nextState, cb) {
        import("pages/ReferralPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/referrals/:referring_user_id",
      onEnter: ({ params }, replace) => {
        if (loggedIn()) {
          replace({
            pathname: "/",
            state: {
              redefined: true, // for progress bar
            },
          })
        } else {
          storeJS.set(REFERRAL_LOCAL_STORAGE_KEY, params.referring_user_id)
          replace("/pricing?referred=true")
        }
      },
    },
    {
      path: "/frameio/callback",
      getComponent(_nextState, cb) {
        import("pages/FrameioCallbackPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/playlists",
      onEnter: (_, replace) => replace({ pathname: "/playlists/music" }),
    },
    {
      path: "/playlists/sfx",
      onEnter: ({ location: { query } }, replace) =>
        replace({ pathname: "/playlists/sound-effects", query }),
    },
    {
      path: "/playlists/:mediaType",
      name: "playlist",
      getComponent(_nextState, cb) {
        import("pages/PlaylistCategoriesPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/playlists/music/:id",
      name: "playlist",
      getComponent(_nextState, cb) {
        import("pages/PlaylistMusicPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/playlists/video/:id",
      name: "playlist",
      getComponent(_nextState, cb) {
        import("pages/PlaylistVideoPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/playlists/sound-effects/:id",
      name: "playlist",
      getComponent(_nextState, cb) {
        import("pages/PlaylistSoundEffectsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/playlists/sfx/:id",
      onEnter: ({ params, location: { query } }, replace) =>
        replace({
          pathname: `/playlists/sound-effects/${params.id}`,
          query,
        }),
    },
    // My Music
    {
      path: "/my_music/following/*",
      onEnter: ({ location }, replace) =>
        replace({ pathname: "/my_media/following/playlists" }),
    },
    {
      path: "/my_media/following/playlists",
      onEnter: ({ location: { query } }, replace) =>
        replace({ pathname: "/my_media/following/playlists/music", query }),
    },
    {
      path: "/my_media/following/playlists/sfx",
      onEnter: ({ location: { query } }, replace) =>
        replace({
          pathname: "/my_media/following/playlists/sound-effects",
          query,
        }),
    },
    {
      path: "/my_media/following/playlists/:mediaType",
      name: "my_media_playlists",
      getComponent(_nextState, cb) {
        import("pages/MyMedia/Following/PlaylistsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/my_media/following/artists",
      getComponent(_nextState, cb) {
        import("pages/MyMedia/Following/ArtistsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/my_music/favorites/*",
      onEnter: ({ location }, replace) =>
        replace({ pathname: "/my_media/favorites" }),
    },
    {
      path: "/my_media/favorites",
      onEnter: ({ location: { query } }, replace) =>
        replace({ pathname: "/my_media/favorites/music", query }),
    },
    {
      path: "/my_media/favorites/sfx",
      onEnter: ({ location: { query } }, replace) =>
        replace({ pathname: "/my_media/favorites/sound-effects", query }),
    },
    {
      path: "/my_media/favorites/:favoritedType",
      name: "my_media_favorites",
      getComponent(_nextState, cb) {
        import("pages/MyMedia/FavoritesPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/my_music/playlists/*",
      onEnter: ({ location }, replace) =>
        replace({ pathname: "/my_media/playlists" }),
    },
    {
      path: "/my_media/playlists",
      onEnter: ({ location: { query } }, replace) =>
        replace({ pathname: "/my_media/playlists/music", query }),
    },
    {
      path: "/my_media/playlists/:mediaType",
      getComponent(_nextState, cb) {
        import("pages/MyMedia/PlaylistsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/my_media/projects",
      getComponent(_nextState, cb) {
        import("pages/MyMedia/ProjectsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/my_media/projects/:hashid",
      getComponent(_nextState, cb) {
        import("pages/MyMedia/ProjectPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/private_playlists/new",
      getComponent(_nextState, cb) {
        const importModules = Promise.all([
          import("containers/PlaylistFormPage/reducer"),
          import("containers/PlaylistFormPage/sagas"),
          import("containers/PlaylistFormPage"),
        ])
        const renderRoute = loadModule(cb)
        importModules.then(([reducer, sagas, component]) => {
          injectReducer("playlist_form", reducer.default)
          injectSagas(sagas.default)
          renderRoute(component)
        })
        importModules.catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/private_playlists/music/:id",
      name: "private_playlist",
      getComponent(_nextState, cb) {
        import("pages/PrivatePlaylistMusicPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/private_playlists/video/:id",
      name: "private_playlist",
      getComponent(_nextState, cb) {
        import("pages/PrivatePlaylistVideoPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/private_playlists/video/:id/edit",
      name: "playlist_form",
      getComponent(_nextState, cb) {
        const importModules = Promise.all([
          import("containers/PlaylistFormPage/reducer"),
          import("containers/PlaylistFormPage/sagas"),
          import("containers/PlaylistFormPage"),
        ])
        const renderRoute = loadModule(cb)
        importModules.then(([reducer, sagas, component]) => {
          injectReducer("playlist_form", reducer.default)
          injectSagas(sagas.default)
          renderRoute(component)
        })
        importModules.catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/private_playlists/sound-effects/:id",
      name: "private_playlist",
      getComponent(_nextState, cb) {
        import("pages/PrivatePlaylistSoundEffectsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/private_playlists/sfx/:id",
      onEnter: ({ params, location: { query } }, replace) =>
        replace({
          pathname: `/private_playlists/sound-effects/${params.id}`,
          query,
        }),
    },
    {
      path: "/private_playlists/sound-effects/:id/edit",
      name: "playlist_form",
      getComponent(_nextState, cb) {
        const importModules = Promise.all([
          import("containers/PlaylistFormPage/reducer"),
          import("containers/PlaylistFormPage/sagas"),
          import("containers/PlaylistFormPage"),
        ])
        const renderRoute = loadModule(cb)
        importModules.then(([reducer, sagas, component]) => {
          injectReducer("playlist_form", reducer.default)
          injectSagas(sagas.default)
          renderRoute(component)
        })
        importModules.catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/private_playlists/sfx/:id/edit",
      onEnter: ({ params, location: { query } }, replace) =>
        replace({
          pathname: `/private_playlists/sound-effects/${params.id}/edit`,
          query,
        }),
    },
    {
      path: "/private_playlists/music/:id/edit",
      name: "playlist_form",
      getComponent(_nextState, cb) {
        const importModules = Promise.all([
          import("containers/PlaylistFormPage/reducer"),
          import("containers/PlaylistFormPage/sagas"),
          import("containers/PlaylistFormPage"),
        ])
        const renderRoute = loadModule(cb)
        importModules.then(([reducer, sagas, component]) => {
          injectReducer("playlist_form", reducer.default)
          injectSagas(sagas.default)
          renderRoute(component)
        })
        importModules.catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/account",
      onEnter: ({ location: { query } }, replace) => {
        replace({ pathname: "/account/profile", query })
      },
    },
    {
      path: "/account/:accountPath",
      name: "account",
      getComponent(_nextState, cb) {
        import("pages/AccountPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/artists",
      getComponent(_nextState, cb) {
        import("pages/ArtistsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/artists/:id",
      getComponent(_nextState, cb) {
        import("pages/ArtistPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/video",
      getComponent(_nextState, cb) {
        import("pages/VideosPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/videos/:id",
      getComponent(_nextState, cb) {
        import("pages/VideoPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/account/profile/edit",
      getComponent(_nextState, cb) {
        import("pages/EditProfilePage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/licenses",
      onEnter: ({ location: { query } }, replace) => {
        replace({ pathname: "/licenses/music", query })
      },
    },
    {
      path: "/licenses/sfx",
      onEnter: ({ location: { query } }, replace) =>
        replace({ pathname: "/licenses/sound-effects", query }),
    },
    {
      path: "/licenses/:mediaType",
      name: "licenses",
      getComponent(_nextState, cb) {
        import("pages/DownloadsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/member_deals",
      getComponent(_nextState, cb) {
        import("pages/MemberDealsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/extensions",
      getComponent(_nextState, cb) {
        import("pages/ExtensionsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/billing_info",
      getComponent(_nextState, cb) {
        import("pages/BillingInfoPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/songs",
      onEnter: ({ location: { query } }, replace) =>
        replace({ pathname: "/music", query }),
    },
    {
      path: "/user_info/:page",
      getComponent(_nextState, cb) {
        import("pages/SignUpUserInfoPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/checkout",
      getComponent(_nextState, cb) {
        import("pages/CheckoutPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: checkoutAuth,
    },
    {
      path: "/onboard",
      getComponent(_nextState, cb) {
        import("pages/OnboardingPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/license_checkout",
      getComponent(_nextState, cb) {
        import("pages/CartCheckoutPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/song_checkout",
      onEnter: ({ location: { query } }, replace) =>
        replace({ pathname: "/license_checkout", query }),
    },
    {
      path: "/market/artists/:artistInfo",
      getComponent(_nextState, cb) {
        import("pages/MarketplacePage/ArtistPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/market_checkout",
      getComponent(_nextState, cb) {
        import("pages/MarketplaceCheckoutPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/signup",
      getComponent(_nextState, cb) {
        import("pages/SignUpPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: signUpRedirect,
    },
    {
      path: "/enterprise/signup",
      getComponent(_nextState, cb) {
        import("pages/EnterpriseSignUpPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: ({ location: { query } }, replace) => {
        if (!query.token) {
          replace("/signup")
        }
      },
    },
    {
      path: "/enterprise",
      getComponent(_nextState, cb) {
        import("pages/EnterpriseContactPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/licensing",
      getComponent(_nextState, cb) {
        import("pages/LicensingContactPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/sign_up",
      onEnter: ({ location: { query } }, replace) => {
        // If token is present, they are signing up as a team member/admin
        if (query.token || query.plan) {
          replace({
            pathname: "/signup",
            query,
          })
        } else {
          replace("/pricing")
        }
      },
    },
    {
      path: "/sign_in",
      getComponent(_nextState, cb) {
        import("pages/SignInPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: preventAuth,
    },
    {
      path: "/forgot_password",
      getComponent(_nextState, cb) {
        import("pages/ForgotPasswordPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: preventAuth,
    },
    {
      path: "/sound-effects",
      getComponent(_nextState, cb) {
        import("pages/SoundEffectsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/sfx",
      onEnter: ({ location: { query } }, replace) =>
        replace({ pathname: "/sound-effects", query }),
    },
    {
      path: "/sound-effects/:id",
      getComponent(_nextState, cb) {
        import("pages/SoundEffectPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/sfx/:id",
      onEnter: ({ params, location: { query } }, replace) =>
        replace({
          pathname: `/sound-effects/${params.id}`,
          query,
        }),
    },
    {
      path: "/sound-effects/:playlistName",
      getComponent(_nextState, cb) {
        import("pages/SoundEffectsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/sfx/:playlistName",
      onEnter: ({ params, location: { query } }, replace) =>
        replace({
          pathname: `/sound-effects/${params.id}`,
          query,
        }),
    },
    {
      path: "/account/content_id/youtube_claims",
      getComponent(_nextState, cb) {
        import("pages/YoutubeClaimsPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/pricing",
      getComponent(_nextState, cb) {
        import("pages/PricingPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/business-pricing",
      getComponent(_nextState, cb) {
        import("pages/BusinessPricingPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/join",
      getComponent(_nextState, cb) {
        import("pages/PricingPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/recently_played",
      getComponent(_nextState, cb) {
        import("pages/RecentlyPlayedPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
      onEnter: requireAuth,
    },
    {
      path: "/careers",
      getComponent(_nextState, cb) {
        import("pages/CareersPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/mobile-app",
      getComponent(_nextState, cb) {
        import("pages/MobileAppPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/royalty-free/:slug",
      getComponent(_nextState, cb) {
        import("pages/PillarPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/free-songs",
      onEnter: (_, replace) => replace({ pathname: "/" }),
    },
    {
      path: "/ai-search",
      getComponent(_nextState, cb) {
        import("pages/AiSearch")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/market",
      onEnter: (_, replace) => replace({ pathname: "/market/browse" }),
    },
    {
      path: "/market/production",
      getComponent(_nextState, cb) {
        import("pages/MarketplaceStockBrowsePage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/market/:storeTab",
      name: "market",
      getComponent(_nextState, cb) {
        import("pages/MarketplacePage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "/market/browse/:genreTag",
      name: "market-browse-genre",
      getComponent(_nextState, cb) {
        import("pages/MarketplacePage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
    {
      path: "*",
      getComponent(_nextState, cb) {
        import("pages/NotFoundPage")
          .then(loadModule(cb))
          .catch(errorLoading)
      },
    },
  ]
}
